import Paragraph from '@library/components/atoms/Paragraph'
import { t } from 'i18next'

import { Button } from '@shared/components/Button'
import { Heading } from '@shared/components/Heading'
import { Label } from '@shared/components/Label'
import { LogoWatermark } from '@shared/components/Logo'
import { Marquee } from '@shared/components/Marquee/Marquee'
import { useMediaQuery } from '@shared/hooks'

export interface FinancePageHeroBlockProps {
  heading: string
  description: string
  onClick: () => void
}

export const FinancePageHeroBlock = ({ heading, description, onClick }: FinancePageHeroBlockProps) => {
  const isMobile = useMediaQuery('max-width: 1028px')
  return (
    <div className="w-full flex flex-col lg:grid lg:grid-cols-2 col-span-12 h-full min-h-[384px] lg:min-h-[420px] xl:min-h-[480px] gap-x-16 place-items-stretch">
      <div className="order-last lg:order-first flex flex-col items-center lg:justify-between gap-y-1 mx-auto w-full">
        <div className="w-full px-6 md:px-[3.75rem] pt-[3.75rem] flex flex-col items-start justify-center gap-y-6">
          <Heading variant="h1" className="text-neutral-primary">
            {heading}
          </Heading>
          <Paragraph size="large" className="text-neutral-secondary">
            {description}
          </Paragraph>
          <Button
            className="h-14 [&>span]:!font-normal [&>svg]:!stroke-2 rounded-xl px-6"
            text={t('patient.financePromoPage.heroBlock.ctaLabel')}
            iconRight="arrow-down"
            onClick={onClick}
          />
        </div>
        <div className="px-6 md:px-[3.75rem] pt-6 md:pt-16 flex flex-col w-full justify-start items-start gap-1 col-span-12 md:col-span-3 ">
          <Label size="small" className="text-neutral-secondary">
            powered by:
          </Label>
          <LogoWatermark colour="black" width={145} className="-ml-2" />
        </div>
      </div>
      <div className="order-first lg:order-last flex flex-col items-center gap-y-12 justify-center bg-neutral-blue-soft rounded-3xl h-[224px] md:h-[386px] lg:h-[36.25rem]">
        <Marquee
          items={[
            '/images/practice/finance-promo/line1image1.svg',
            '/images/practice/finance-promo/line1image2.svg',
            '/images/practice/finance-promo/line1image3.svg',
            '/images/practice/finance-promo/line1image4.svg',
            '/images/practice/finance-promo/line1image5.svg',
            '/images/practice/finance-promo/line1image6.svg',
          ]}
          direction="Left"
        />
        {!isMobile && (
          <Marquee
            items={[
              '/images/practice/finance-promo/line2image1.svg',
              '/images/practice/finance-promo/line2image2.svg',
              '/images/practice/finance-promo/line2image3.svg',
              '/images/practice/finance-promo/line2image4.svg',
              '/images/practice/finance-promo/line2image5.svg',
              '/images/practice/finance-promo/line2image6.svg',
            ]}
            direction="Right"
          />
        )}
      </div>
    </div>
  )
}
