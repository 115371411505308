import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import LoggedInNav from '@patient/components/LoggedInNav'
import { Footer } from '@patient/templates/Footer/Footer'
import { EmptyState } from '@shared/components/EmptyState'
import { Spinner } from '@shared/components/Spinner'
import { useSetInstalments } from '@shared/hooks/useGetInstalments/useGetInstalments'
import { usePatientPracticeStore } from '@shared/stores/patientPracticeStore'
import { extractPracticeLogo } from '@shared/utils/extractPracticeLogo'

export const AuthWrapper = () => {
  const navigate = useNavigate()
  const { practices, firstName, lastName, isLoading, isError, getPatientPracticeDetails } = usePatientPracticeStore()

  const offersFinance = practices[0] && practices[0].offersFinance

  const setInstalments = useSetInstalments(offersFinance)

  useEffect(() => {
    if (offersFinance) setInstalments()
  }, [setInstalments, offersFinance])

  useEffect(() => {
    if (!practices[0]) {
      getPatientPracticeDetails()
    }
  }, [practices, getPatientPracticeDetails])

  if (isLoading) return <Spinner centered />

  if (!practices[0]) return <EmptyState text="No practices found" />
  const [{ logoUrl, name: practiceName }] = practices

  if (isError) {
    navigate('/patient/error', { replace: true })
    return null
  }

  return (
    <div>
      <LoggedInNav patientName={`${firstName} ${lastName}`} logoUrl={extractPracticeLogo(logoUrl)} />
      <main className="xl:pt-0">{<Outlet />}</main>
      <Footer practiceName={practiceName} />
    </div>
  )
}
