interface HandleReactQueryErrorsArgs {
  status: number
  errorMessage: string
}

const INVALID_LINK = 'Link provided is invalid.'
const EXPIRED_LINK = 'Link provided has expired.'
const DEVICE_MISMATCH = 'User accessing from a new device.'

export const INVALID_LINK_MESSAGE = 'invalid-link'
export const EXPIRED_LINK_MESSAGE = 'expired-link'
export const DEVICE_MISMATCH_MESSAGE = 'device-mismatch'

export type LoginCodes = typeof INVALID_LINK_MESSAGE | typeof EXPIRED_LINK_MESSAGE | typeof DEVICE_MISMATCH_MESSAGE

export const handleMagcLinkErrors = ({ errorMessage, status }: HandleReactQueryErrorsArgs) => {
  switch (errorMessage) {
    case INVALID_LINK:
      return window.location.assign(`/patient/login?type=${INVALID_LINK_MESSAGE}`)
    case EXPIRED_LINK:
      return window.location.assign(`/patient/login?type=${EXPIRED_LINK_MESSAGE}`)
    case DEVICE_MISMATCH:
      return window.location.assign(`/patient/login?type=${DEVICE_MISMATCH_MESSAGE}`)
  }
  if (status == 401) {
    return window.location.assign('/patient/login')
  }
}
