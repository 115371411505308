import { memo, useEffect } from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { Spinner } from '@shared/components/Spinner'
import { useStripeProductCheckoutSession, useStripeSubscriptionCheckoutSession } from '@shared/data/patient/hooks'
import { useFeatureFlags } from '@shared/hooks/useFeatureFlags'

export const CONNECTED_ACCOUNT_ID = 'acct_1PfU7yPxZ6lwrDde'

const stripePromise = loadStripe(
  'pk_test_51NZt7JLlCuZX0HRWa75BD6ewxBhBPnKtmuFuGmU4jF0AO6drV8XdG20iVoo0Aw4fu4ByceTu9Tb2wBEYvOz638CE00xTc9uQCA',
  { stripeAccount: CONNECTED_ACCOUNT_ID },
)

export const CheckoutStripe = memo(() => {
  const featureFlags = useFeatureFlags()
  const [searchParams] = useSearchParams()
  const planId = searchParams.get('plan_id') || undefined
  const checkoutSession = planId ? useStripeSubscriptionCheckoutSession : useStripeProductCheckoutSession
  const { data, mutate } = checkoutSession()

  useEffect(() => {
    mutate({
      connectedAccountId: CONNECTED_ACCOUNT_ID,
      planId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!featureFlags.payViaPracti) return <Navigate to="/patient/dashboard" />

  return data?.clientSecret ? (
    <div className="pt-4 pb-8">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={data}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  ) : (
    <Spinner centered />
  )
})

CheckoutStripe.displayName = 'CheckoutStripe'
