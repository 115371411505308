import { Typography } from '../Typography'

type EmptyStateProps = {
  text: string
}
export const EmptyState = ({ text }: EmptyStateProps) => {
  return (
    <div className="flex justify-center items-center p-12 bg-primary-light mt-8 rounded-2xl text-grey-500">
      <Typography element="h6" variant="h6">
        {text}
      </Typography>
    </div>
  )
}
