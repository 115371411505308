import {
  CheckoutSession,
  CheckoutSessionArgs,
  CreateOrderArgs,
  PatientFinanceManual,
  PatientFinanceRedirect,
  PatientOrderStatus,
  PatientOrderStatusArgs,
  PatientResetMagicLinkArgs,
  PatientSoftSearchKeyArgs,
  PatientUserDetailsPatch,
  SoftSearchLink,
  UpdateAnswers,
  UpdateConfirmationArgs,
  UpdateDecisionArgs,
  UpdateStripePaymentStatusArgs,
} from '@shared/data/types'
import { patientFetch as fetch } from '@shared/utils/fetch'
import { apiUrls } from '@shared/utils/urls'

const fetchers = {
  PATIENT_USER_DETAILS: (data: PatientUserDetailsPatch): Promise<PatientUserDetailsPatch> =>
    fetch({ url: apiUrls.patientUserDetails, method: 'patch', data }),
  PATIENT_QUIZ: ({
    type,
    ...data
  }: UpdateAnswers & { type: 'smile' | 'feedback' }): Promise<UpdateAnswers & { type: 'smile' | 'feedback' }> =>
    fetch({ url: apiUrls.patientQuiz(type), method: 'post', data: { quizData: data } }),
  PATIENT_SOFT_SEARCH_KEY: (data: PatientSoftSearchKeyArgs): Promise<SoftSearchLink> =>
    fetch({ url: apiUrls.patientSoftSearchKey, method: 'post', data }),
  PATIENT_FINANCE_REDIRECT: (): Promise<PatientFinanceRedirect> =>
    fetch({ url: apiUrls.patientFinanceRedirect, method: 'post' }),
  PATIENT_FINANCE_MANUAL: (): Promise<PatientFinanceManual> =>
    fetch({ url: apiUrls.patientFinanceManual, method: 'post' }),
  PATIENT_ORDER_STATUS: (params: PatientOrderStatusArgs): Promise<PatientOrderStatus> =>
    fetch({ url: apiUrls.patientOrderStatus, params }),
  PATIENT_CREATE_ORDER: (data: CreateOrderArgs): Promise<null> =>
    fetch({ url: apiUrls.patientCreateOrder, method: 'post', data }),
  PATIENT_RESET_MAGIC_LINK: (data: PatientResetMagicLinkArgs): Promise<null> =>
    fetch({ url: apiUrls.patientResetMagicLink, method: 'post', data }),
  PATIENT_UPDATE_DECISION: (data: UpdateDecisionArgs): Promise<null> =>
    fetch({ url: apiUrls.patientUpdateDecision, method: 'post', data }),
  PATIENT_UPDATE_CONFIRMATION: (data: UpdateConfirmationArgs): Promise<null> =>
    fetch({ url: apiUrls.patientConfirmationSeen, method: 'post', data }),
  STRIPE_CHECKOUT_SESSION: (data: CheckoutSessionArgs): Promise<CheckoutSession> =>
    fetch({ url: apiUrls.stripeCheckoutSession, method: 'post', data }),
  STRIPE_SUBSCRIPTION_CHECKOUT_SESSION: (data: CheckoutSessionArgs): Promise<CheckoutSession> =>
    fetch({ url: apiUrls.stripeSubscriptionCheckoutSession, method: 'post', data }),
  UPDATE_STRIPE_PAYMENT_STATUS: (data: UpdateStripePaymentStatusArgs): Promise<null> =>
    fetch({ url: apiUrls.updateStripePaymentStatus, method: 'post', data }),
}

export const mutations = {
  PATIENT_USER_DETAILS: {
    mutationFn: (data: PatientUserDetailsPatch) => fetchers.PATIENT_USER_DETAILS(data),
  },
  PATIENT_QUIZ: {
    mutationFn: (data: UpdateAnswers & { type: 'smile' | 'feedback' }) => fetchers.PATIENT_QUIZ(data),
  },
  PATIENT_SOFT_SEARCH_KEY: {
    mutationFn: (data: PatientSoftSearchKeyArgs) => fetchers.PATIENT_SOFT_SEARCH_KEY(data),
  },
  PATIENT_FINANCE_REDIRECT: {
    mutationFn: () => fetchers.PATIENT_FINANCE_REDIRECT(),
  },
  PATIENT_FINANCE_MANUAL: {
    mutationFn: () => fetchers.PATIENT_FINANCE_MANUAL(),
  },
  PATIENT_ORDER_STATUS: {
    mutationFn: (params: PatientOrderStatusArgs) => fetchers.PATIENT_ORDER_STATUS(params),
  },
  PATIENT_CREATE_ORDER: {
    mutationFn: (data: CreateOrderArgs) => fetchers.PATIENT_CREATE_ORDER(data),
  },
  PATIENT_RESET_MAGIC_LINK: {
    mutationFn: (data: PatientResetMagicLinkArgs) => fetchers.PATIENT_RESET_MAGIC_LINK(data),
  },
  PATIENT_UPDATE_DECISION: {
    mutationFn: (data: UpdateDecisionArgs) => fetchers.PATIENT_UPDATE_DECISION(data),
  },
  PATIENT_UPDATE_CONFIRMATION: {
    mutationFn: (data: UpdateConfirmationArgs) => fetchers.PATIENT_UPDATE_CONFIRMATION(data),
  },
  STRIPE_CHECKOUT_SESSION: {
    mutationFn: (data: CheckoutSessionArgs) => fetchers.STRIPE_CHECKOUT_SESSION(data),
  },
  STRIPE_SUBSCRIPTION_CHECKOUT_SESSION: {
    mutationFn: (data: CheckoutSessionArgs) => fetchers.STRIPE_SUBSCRIPTION_CHECKOUT_SESSION(data),
  },
  UPDATE_STRIPE_PAYMENT_STATUS: {
    mutationFn: (data: UpdateStripePaymentStatusArgs) => fetchers.UPDATE_STRIPE_PAYMENT_STATUS(data),
  },
}
