import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { DivideBuyInstalment } from '@shared/data/types'

type InstalmentFlags = {
  regulated: boolean
  unregulated: boolean
}
type InstalmentData = {
  instalmentsRaw: DivideBuyInstalment[]
  representativeExample?: DivideBuyInstalment
  instalmentFlags?: InstalmentFlags
  maxLoanAmount?: string
  minLoanAmount?: string
  isLoading: boolean
  isError: boolean
  errorMessage: string
  amount?: string
}
type InstalmentsAction = {
  setInstalmentsData: (data: {
    instalmentsRaw?: DivideBuyInstalment[]
    representativeExample?: DivideBuyInstalment
    instalmentFlags?: InstalmentFlags
    isLoading?: boolean
    maxLoanAmount?: string
    minLoanAmount?: string
    isError?: boolean
    errorMessage?: string
    amount?: string
  }) => void
}

export const useFinanceInstalmentStore = create<InstalmentData & InstalmentsAction>()(
  devtools(
    (set) => ({
      instalmentsRaw: [],
      isLoading: false,
      isError: false,
      errorMessage: '',
      setInstalmentsData: (data) => {
        set(data)
      },
    }),
    {
      name: 'instalmentData',
    },
  ),
)
