import { ReactNode } from 'react'
import { Disclosure } from '@headlessui/react'
import { clsx } from 'clsx'

import { Icon, IconNameType } from '@shared/components/Icon'
import { Typography } from '@shared/components/Typography'
import { ImageBlock } from '@shared/content-blocks/ImageBlock'

import { Heading } from '../Heading'

type AccordianIcon = {
  open: IconNameType
  close: IconNameType
}
export type AccordianItem = {
  title: string
  subtitle?: string
  description: ReactNode
  titleInfo?: string
  content?: string
  imageUrl?: string
  carousel?: { slide: string }[]
}

type Props = {
  showImages?: boolean
  header?: string
  newHeader?: string
  icons: AccordianIcon
  items: AccordianItem[]
  className?: string
  borderColor?: string
  subHeading?: string
  onOpen?: () => void
}

export const Accordian = ({
  showImages,
  icons,
  className,
  header,
  newHeader,
  items,
  subHeading,
  borderColor,
  onOpen,
}: Props) => (
  <div className={clsx('w-full', className)}>
    {header && (
      <Typography variant="h3" className={clsx(subHeading ? 'mb-4' : 'mb-12')}>
        {header}
      </Typography>
    )}
    {newHeader && (
      <Heading variant="h3" className={clsx(subHeading ? 'mb-4' : 'mb-12')}>
        {newHeader}
      </Heading>
    )}
    {subHeading && (
      <Typography variant="b20" className="mb-12 text-grey-600">
        {subHeading}
      </Typography>
    )}
    {items.map(({ title, subtitle, imageUrl, description, carousel }) => (
      <Disclosure
        key={title}
        as="div"
        className={clsx(borderColor ? `${borderColor}` : 'border-grey-200', 'border-b py-8')}
      >
        {({ open }) => (
          <>
            <Disclosure.Button as="div" className={open ? 'mb-4' : ''}>
              <button
                className="item-start flex w-full justify-between"
                onClick={() => {
                  if (!open) onOpen?.()
                }}
              >
                <div className="items-start flex-col md:flex-row flex gap-y-4 md:gap-y-0 gap-x-6 justify-start w-full">
                  {showImages && imageUrl && (
                    <ImageBlock
                      url={imageUrl ? imageUrl : '/images/patient/treatments/treatmentPreviewTest.png'}
                      className="w-[7.5rem] md:w-40 h-auto rounded-md md:rounded-lg"
                    />
                  )}
                  <div className="flex flex-col items-start gap-4 md:w-4/6">
                    <Typography variant="h6" className={clsx(subtitle && 'mb-4', 'text-left')}>
                      {title}
                    </Typography>
                    {description && carousel && (
                      <Typography variant="b16" className="text-left text-grey-600">
                        {description}
                      </Typography>
                    )}
                    {open && showImages && imageUrl && carousel?.length && (
                      <>
                        {description && carousel ? (
                          <Typography variant="b16" className="text-left text-grey-600">
                            {carousel.map(({ slide }) => (
                              <>
                                {slide}
                                <br />
                                <br />
                              </>
                            ))}
                          </Typography>
                        ) : (
                          <Typography variant="b16" className="text-left text-grey-600">
                            {description}
                          </Typography>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <Icon size={30} name={open ? icons.open : icons.close} className="stroke-primary-dark" />
              </button>
            </Disclosure.Button>
            {!showImages && !imageUrl && (
              <Disclosure.Panel className="text-left ">
                <Typography variant="b16" className="text-grey-600">
                  {description}
                </Typography>
              </Disclosure.Panel>
            )}
          </>
        )}
      </Disclosure>
    ))}
  </div>
)
