import {
  DivideBuyInstalment,
  FileUrl,
  GuidePractitioner,
  PatientInfo,
  PatientUserDetails,
  PracticeDetails,
  PracticePatients,
  PracticePratitioners,
  PractitionerUserDetails,
  TreatmentDefinition,
} from '@shared/data/types'
import { practiceFetch as fetch, unprotectedFetch } from '@shared/utils/fetch'
import { apiUrls } from '@shared/utils/urls'

const fetchers = {
  PRACTITIONER_PATIENT_INFO: ({ queryKey }: { queryKey: string[] }): Promise<PatientInfo> =>
    fetch({ url: apiUrls.patientInfo(queryKey[1]) }),
  PATIENT_USER_DETAILS: (): Promise<PatientUserDetails> => fetch({ url: apiUrls.patientUserDetails }),
  PRACTITIONER_USER_DETAILS: (): Promise<PractitionerUserDetails> => fetch({ url: apiUrls.practitionerUserDetails }),
  PRACTICE_PATIENT_LIST: (): Promise<PracticePatients> => fetch({ url: apiUrls.patientList }),
  PRACTICE_PRACTITIONER_LIST: (): Promise<PracticePratitioners> => fetch({ url: apiUrls.practitionerList }),
  TREATMENT_DEFINITION: ({ queryKey }: { queryKey: string[] }): Promise<TreatmentDefinition> =>
    fetch({ url: apiUrls.treatmentDefinition(queryKey[1]) }),
  PRACTICE_DETAILS: ({ queryKey }: { queryKey: string[] }): Promise<PracticeDetails> =>
    fetch({ url: apiUrls.practiceDetails(queryKey[1]) }),
  PRACTICE_DETAILS_UNPROTECTED: ({ queryKey }: { queryKey: string[] }): Promise<PracticeDetails> =>
    unprotectedFetch({ url: apiUrls.practiceDetailsUnprotected(queryKey[1]) }),
  PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED: ({ queryKey }: { queryKey: string[] }): Promise<DivideBuyInstalment[]> =>
    unprotectedFetch({ url: apiUrls.practiceAvailableInstalmentsUnprotected(queryKey[1], queryKey[2]) }),
  GUIDE_PRACTITIONER: ({ queryKey }: { queryKey: string[] }): Promise<GuidePractitioner[]> =>
    fetch({ url: apiUrls.practiceGuidePractitionerGet(queryKey[1]) }),
  PRACTITIONER_TREATMENT_PLAN: ({ queryKey }: { queryKey: string[] }): Promise<FileUrl> | false => {
    if (queryKey[1]) {
      return fetch({ url: apiUrls.patientTreatmentPlan(queryKey[1]) })
    }
    return false
  },
  PRACTICE_AVAILABLE_INSTALMENTS: ({ queryKey }: { queryKey: string[] }): Promise<DivideBuyInstalment[]> =>
    fetch({
      method: 'POST',
      url: apiUrls.practiceAvailableInstalments,
      data: { orderTotal: queryKey[1], treatmentGuideId: queryKey[2] },
    }),
}

export const queries = {
  PRACTITIONER_PATIENT_INFO: {
    queryKey: ['PRACTITIONER_PATIENT_INFO'],
    queryFn: fetchers.PRACTITIONER_PATIENT_INFO,
  },
  PRACTICE_PATIENT_LIST: {
    queryKey: ['PRACTICE_PATIENT_LIST'],
    queryFn: fetchers.PRACTICE_PATIENT_LIST,
  },
  PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED: {
    queryKey: ['PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED'],
    queryFn: fetchers.PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED,
  },
  PRACTICE_PRACTITIONER_LIST: {
    queryKey: ['PRACTICE_PRACTITIONER_LIST'],
    queryFn: fetchers.PRACTICE_PRACTITIONER_LIST,
  },
  PRACTICE_DETAILS: {
    queryKey: ['PRACTICE_DETAILS'],
    queryFn: fetchers.PRACTICE_DETAILS,
  },
  PRACTICE_DETAILS_UNPROTECTED: {
    queryKey: ['PRACTICE_DETAILS_UNPROTECTED'],
    queryFn: fetchers.PRACTICE_DETAILS_UNPROTECTED,
  },
  PRACTITIONER_USER_DETAILS: {
    queryKey: ['PRACTITIONER_USER_DETAILS'],
    queryFn: fetchers.PRACTITIONER_USER_DETAILS,
  },
  TREATMENT_DEFINITION: {
    queryKey: ['TREATMENT_DEFINITION'],
    queryFn: fetchers.TREATMENT_DEFINITION,
  },
  PRACTITIONER_TREATMENT_PLAN: {
    queryKey: ['PRACTITIONER_TREATMENT_PLAN'],
    queryFn: fetchers.PRACTITIONER_TREATMENT_PLAN,
  },
  GUIDE_PRACTITIONER: {
    queryKey: ['GUIDE_PRACTITIONER'],
    queryFn: fetchers.GUIDE_PRACTITIONER,
  },
  PRACTICE_AVAILABLE_INSTALMENTS: {
    queryKey: ['PRACTICE_AVAILABLE_INSTALMENTS'],
    queryFn: fetchers.PRACTICE_AVAILABLE_INSTALMENTS,
  },
}
