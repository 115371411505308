import { t } from 'i18next'

import { Grid } from '../Grid'
import { LogoWatermark } from '../Logo'
import { Typography } from '../Typography'

export const ErrorBoundaryFallback = () => (
  <div className="p-4 min-h-screen">
    <div className="bg-accent1-900 rounded-[32px] p-4 min-h-[calc(100vh-32px)] md:px-20 xl:px-32 flex items-center overflow-hidden">
      <Grid>
        <div className="col-span-12 max-w-[500px] text-center">
          <div className="flex flex-col items-center">
            <div className="mb-6">
              <LogoWatermark colour="white" width={300} />
            </div>
            <Typography variant="h2" className="text-center text-white pb-12">
              {t('common.errorPage.title')}
            </Typography>
            <Typography element="p" variant="b24" className="text-center text-white pb-16">
              {t('common.errorPage.refresh')}
            </Typography>
            <div className="text-primary-main underline flex justify-center"></div>
          </div>
        </div>
      </Grid>
    </div>
  </div>
)
