import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { t } from 'i18next'
import { useDebouncedCallback } from 'use-debounce'

import { FinancePromoFooter } from '@practice/components/FinancePromoFooter/FinancePromoFooter'
import { Accordian } from '@shared/components/Accordian'
import { Grid } from '@shared/components/Grid'
import {
  usePracticeAvailableInstalmentsUnprotected,
  usePracticeDetailsUnprotectedQuery,
} from '@shared/data/practice/hooks'
import { DivideBuyInstalment } from '@shared/data/types'
import { extractPracticeLogo } from '@shared/utils/extractPracticeLogo'

import { FinanceCalculator } from '../../components/FinanceCalculator/FinanceCalculator'
import { FinanceContactBlock } from '../../components/FinanceContactBlock/FinanceContactBlock'
import { FinanceHowItWorks } from '../../components/FinanceHowItWorks/FinanceHowItWorks'
import { FinancePageHeroBlock } from '../../components/FinancePageHeroBlock/FinancePageHeroBlock'

type MinAvailableInstalment = DivideBuyInstalment & {
  min_price: number
}

type MaxAvailableInstalment = DivideBuyInstalment & {
  max_price: number
}
const termLengths = [6, 12, 24, 36]

export const FinancePromoPage = () => {
  const [searchParams] = useSearchParams()
  const [loanAmount, setLoanAmount] = useState<string>('1000')
  const [selectedTerm, setSelectedTerm] = useState<number>()

  const practiceId = searchParams.get('id')

  const {
    refetch: getInstalments,
    data,
    isLoading,
  } = usePracticeAvailableInstalmentsUnprotected({
    orderTotal: loanAmount,
    practiceId: practiceId!,
  })

  const handleSetSelectedTerm = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setSelectedTerm(parseInt((event.target as HTMLButtonElement).value))
  }, [])

  useEffect(() => {
    if (loanAmount && practiceId) getInstalments()
  }, [loanAmount, practiceId, getInstalments])

  const handleSetLoanAmount = useDebouncedCallback((event: ChangeEvent<HTMLInputElement>) => {
    setLoanAmount(event.target.value)
  }, 500)

  const noAvailableInstalments =
    data && data.every(({ available, type }) => !available || type === 'representative_example')

  const minLoanAmount = useMemo(
    () =>
      Math.min(
        ...(
          (data || []).filter(
            ({ min_price, type }) => min_price !== null && type !== 'representative_example',
          ) as MinAvailableInstalment[]
        ).map(({ min_price }) => min_price),
      ).toString(),
    [data],
  )

  const maxLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          (data || []).filter(
            ({ max_price, type }) => max_price !== null && type !== 'representative_example',
          ) as MaxAvailableInstalment[]
        ).map(({ max_price }) => max_price),
      ).toString(),
    [data],
  )

  const activeTermData = useMemo(
    () =>
      (data || []).find(
        ({ available, term_length, type }) =>
          type !== 'representative_example' && available && term_length === selectedTerm,
      ),
    [selectedTerm, data],
  )

  useEffect(() => {
    const availableTerm = data?.find(({ available, type }) => type !== 'representative_example' && available)
    if (availableTerm) {
      setSelectedTerm(availableTerm?.term_length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const error = useMemo(() => {
    if (parseInt(loanAmount) > parseInt(maxLoanAmount)) {
      return `There are no options available for amounts above £${maxLoanAmount}`
    } else if (parseInt(loanAmount) < parseInt(minLoanAmount)) {
      return `There are no options available for amounts below £${minLoanAmount}`
    } else if (!data?.find((instalment) => instalment.term_length === selectedTerm)?.available) {
      return 'The chosen number of monthly payments is not an option for this amount.'
    } else {
      return ''
    }
  }, [loanAmount, data, selectedTerm, minLoanAmount, maxLoanAmount])

  // Get practice data
  const { data: practiceData, isLoading: practiceLoading } = usePracticeDetailsUnprotectedQuery(practiceId || '')
  const practiceLogo = practiceData?.logoUrl
  const practiceEmail = practiceData?.email
  const practicePhone = practiceData?.phone

  const scrollTo = () => {
    const calculator = document.getElementById('finance-calculator')
    if (calculator && calculator.offsetTop) {
      window.scrollTo({ top: calculator.offsetTop - 50 || 0, behavior: 'smooth' })
    }
  }

  return (
    <>
      <div className="fixed bg-gradient-to-b from-white to-transparent top-0 inset-0 h-[5rem] p-8 flex items-center justify-start">
        {practiceLogo && !practiceLoading && (
          <img
            alt="practice logo"
            className="h-[50px]"
            src={extractPracticeLogo(practiceLogo)}
            onError={({ currentTarget }) => {
              currentTarget.src = '/images/patient/logo.svg'
            }}
          />
        )}
      </div>
      <Grid className="mt-[5rem] !p-0 !max-w-[75rem] mx-auto">
        <FinancePageHeroBlock
          onClick={scrollTo}
          heading={t('patient.financePromoPage.heroBlock.heading')}
          description={t('patient.financePromoPage.heroBlock.subheading')}
        />
      </Grid>
      <Grid className="mt-[5rem] !p-0 !max-w-[75rem] mx-auto">
        <FinanceHowItWorks />
        <FinanceCalculator
          activeTermData={activeTermData}
          data={data}
          handleSetLoanAmount={handleSetLoanAmount}
          error={error}
          isLoading={isLoading}
          loanAmount={loanAmount}
          termLengths={termLengths}
          handleSetSelectedTerm={handleSetSelectedTerm}
          selectedTerm={selectedTerm}
          noAvailableInstalments={noAvailableInstalments}
        />
        <FinanceContactBlock email={practiceEmail} phone={practicePhone} />
        <Accordian
          icons={{ open: 'chevron-up', close: 'chevron-down' }}
          className="p-6 lg:p-[3.75rem] col-span-12 my-12 xl:max-w-[1090px] mx-auto"
          borderColor="border-neutral-subtle"
          newHeader={t('patient.financePromoPage.faqBlock.heading')}
          items={[
            {
              title: t('patient.financePromoPage.faqBlock.question1.text'),
              description: t('patient.financePromoPage.faqBlock.question1.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question2.text'),
              description: t('patient.financePromoPage.faqBlock.question2.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question3.text'),
              description: t('patient.financePromoPage.faqBlock.question3.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question4.text'),
              description: t('patient.financePromoPage.faqBlock.question4.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question5.text'),
              description: t('patient.financePromoPage.faqBlock.question5.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question6.text'),
              description: t('patient.financePromoPage.faqBlock.question6.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question7.text'),
              description: (
                <p>
                  {t('patient.financePromoPage.faqBlock.question7.answer')}
                  <a
                    className="text-primary-main underline decoration-2 underline-offset-4"
                    target="_blank"
                    href={t('patient.financePromoPage.faqBlock.question7.link.href')}
                    rel="noreferrer"
                  >
                    {t('patient.financePromoPage.faqBlock.question7.link.text')}
                  </a>
                </p>
              ),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question8.text'),
              description: t('patient.financePromoPage.faqBlock.question8.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question9.text'),
              description: t('patient.financePromoPage.faqBlock.question9.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question10.text'),
              description: t('patient.financePromoPage.faqBlock.question10.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question11.text'),
              description: t('patient.financePromoPage.faqBlock.question11.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question13.text'),
              description: t('patient.financePromoPage.faqBlock.question13.answer'),
            },
            {
              title: t('patient.financePromoPage.faqBlock.question14.text'),
              description: t('patient.financePromoPage.faqBlock.question14.answer'),
            },
          ]}
        />
      </Grid>
      <FinancePromoFooter />
    </>
  )
}
