import { HTMLAttributes } from 'react'
import clsx from 'clsx'

const xSmallStyles = `
  font-normal
  text-[0.75rem]
  leading-[1.25rem]
  tracking-[0.015rem];
  text-neutral-primary
`

const smallStyles = `
  font-normal
  text-[0.875rem]
  leading-[1.25rem]
  tracking-[0.015rem];
  text-neutral-primary
`

const mediumStyles = `
  font-normal
  text-base
  leading-6
  tracking-[0.32px];
  text-neutral-primary
`

const largeStyles = `
  text-xl
  font-normal
  leading-6
  tracking-[0.4px]
  text-neutral-primary
`

const xLargeStyles = `
  text-xl
  font-normal
  leading-8
  tracking-[0.4px]
  text-neutral-primary
`

type PargraphSize = 'xSmall' | 'small' | 'medium' | 'large' | 'xLarge'

interface ParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
  size: PargraphSize
}

export const Paragraph = ({ className, children, size, ...props }: ParagraphProps) => {
  let sizeStyles = ''
  switch (size) {
    case 'xSmall':
      sizeStyles = xSmallStyles
      break
    case 'small':
      sizeStyles = smallStyles
      break
    case 'medium':
      sizeStyles = mediumStyles
      break
    case 'large':
      sizeStyles = largeStyles
      break
    case 'xLarge':
      sizeStyles = xLargeStyles
      break
  }
  return (
    <p className={clsx(className, sizeStyles)} {...props}>
      {children}
    </p>
  )
}
