import { CSSProperties } from 'react'

import { Typography } from '../Typography'
type SpinnerPositions = {
  centered?: boolean
  overlay?: boolean
  text?: string
  inButton?: boolean
}

export const Spinner = ({ centered = true, inButton, overlay, text }: SpinnerPositions) => {
  const SpinnerContent = (
    <div className="flex flex-col gap-8 items-center">
      {text && (
        <Typography element="p" variant="b20">
          {text}
        </Typography>
      )}
      <div data-testid="spinner" className="lds-ellipsis" aria-label="loading...">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
  const props = {
    ...(centered && {
      style: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        left: '0',
        top: '0',
        bottom: '0',
      },
    }),
    ...(inButton && { style: { margin: '0 1rem' } }),
  } as { style: CSSProperties }

  const SpinnerComponent = centered || inButton ? <div {...props}>{SpinnerContent}</div> : SpinnerContent

  return (
    <>
      {overlay ? (
        <div className="flex bg-primary-contrast z-[9999] top-0 left-0 bottom-0 right-0 items-center justify-center fixed">
          {SpinnerContent}
        </div>
      ) : (
        SpinnerComponent
      )}
    </>
  )
}
