import { useMutation, useQuery } from '@tanstack/react-query'

import { queries } from '@shared/data/practice/queries'
import {
  AddGuidePractitioner,
  AddPractitioner,
  AddTreatmentPlan,
  AttachmentSuccess,
  CheckoutSessionArgs,
  DivideBuyInstalment,
  FetchError,
  GuidePractitioner,
  GuidePractitionerPatch,
  MultiAddAttachments,
  MultiAttachmentUrl,
  MultiPresignedUrl,
  Patient,
  PatientInfo,
  PatientSignUp,
  PracticeDetails,
  PracticeDetailsPatch,
  PracticePatients,
  PracticePratitioners,
  Practitioner,
  PractitionerDetailsPatch,
  PractitionerUserDetails,
  PresignedUrl,
  SendPlanArgs,
  StripeSession,
  TreatmentDefinition,
  TreatmentGuide,
  TreatmentGuideId,
  TreatmentPlanSuccess,
  TreatmentPlanUrl,
  UpdateIsArchivedArgs,
  UpdateTreatmentGuideArgs,
} from '@shared/data/types'

import { mutations } from './mutations'

// NOTE: custom hooks make testing easier
// https://tanstack.com/query/v4/docs/react/guides/testing

export const usePracticePatientListQuery = () =>
  useQuery<unknown, FetchError, PracticePatients>(queries.PRACTICE_PATIENT_LIST)

export const usePracticeAvailableInstalmentsUnprotected = ({
  orderTotal,
  practiceId,
}: {
  orderTotal: string
  practiceId: string
}) =>
  useQuery<DivideBuyInstalment[], FetchError, DivideBuyInstalment[], string[]>({
    queryKey: [queries.PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED.queryKey[0], orderTotal, practiceId],
    queryFn: queries.PRACTICE_AVAIALBLE_INSTALMENTS_UNPROTECTED.queryFn,
    enabled: false,
  })

export const usePracticeDetailsUnprotectedQuery = (practiceId: string) =>
  useQuery<PracticeDetails, FetchError, PracticeDetails, string[]>({
    queryKey: [queries.PRACTICE_DETAILS_UNPROTECTED.queryKey[0], practiceId as string],
    queryFn: queries.PRACTICE_DETAILS_UNPROTECTED.queryFn,
    enabled: Boolean(practiceId),
  })

export const usePracticePractitionersListQuery = (options?: {
  enabled?: boolean
  onSuccess: (data: PracticePratitioners) => void
}) =>
  useQuery<PracticePratitioners, FetchError, PracticePratitioners>({
    ...queries.PRACTICE_PRACTITIONER_LIST,
    ...(options && options),
    enabled: options?.enabled ?? true,
  })

export const usePractitionerUserDetailsQuery = (enabled = true) =>
  useQuery<PractitionerUserDetails, FetchError>({
    ...queries.PRACTITIONER_USER_DETAILS,
    enabled,
  })

export const usePracticeDetailsQuery = (practiceId: string) =>
  useQuery<PracticeDetails, FetchError, PracticeDetails, string[]>({
    queryKey: [queries.PRACTICE_DETAILS.queryKey[0], practiceId as string],
    queryFn: queries.PRACTICE_DETAILS.queryFn,
    enabled: Boolean(practiceId),
  })

export const usePracticeDetailsMutation = (options?: { onSuccess: () => void }) =>
  useMutation<PracticeDetails, FetchError, PracticeDetailsPatch>({
    mutationFn: mutations.PRACTICE_DETAILS.mutationFn,
    ...(options && options),
  })

export const useAddPractitionerMutation = (options?: {
  onSuccess: (data: Practitioner) => void
  onError?: (err: FetchError) => void
}) =>
  useMutation<Practitioner, FetchError, AddPractitioner>({
    mutationFn: mutations.PRACTITIONER_ADD.mutationFn,
    ...(options && options),
  })

export const usePractitionerUpdateDetailsMutation = (options?: { onSuccess: () => void }) =>
  useMutation<Practitioner, FetchError, PractitionerDetailsPatch>({
    mutationFn: mutations.PRACTITIONER_UPDATE.mutationFn,
    ...(options && options),
  })

export const useAddGuidePractitionerMutation = (options?: { onSuccess: () => void }) =>
  useMutation<AddGuidePractitioner, FetchError, AddGuidePractitioner>({
    mutationFn: mutations.GUIDE_PRACTITIONER_ADD.mutationFn,
    ...(options && options),
  })

export const useUpdateGuidePractitionerMutation = (options?: { onSuccess: () => void }) =>
  useMutation<GuidePractitioner, FetchError, GuidePractitionerPatch>({
    mutationFn: mutations.GUIDE_PRACTITIONER_UPDATE.mutationFn,
    ...(options && options),
  })

export const useTreatmentDefinitionQuery = (treatmentName: string | undefined, options?: { enabled: boolean }) =>
  useQuery<TreatmentDefinition, FetchError, TreatmentDefinition, string[]>({
    queryKey: [queries.TREATMENT_DEFINITION.queryKey[0], treatmentName as string],
    queryFn: queries.TREATMENT_DEFINITION.queryFn,
    ...(options && options),
  })

export const usePractitionerTreatmentGuideMutation = (options?: { onSuccess: (data: TreatmentGuideId) => void }) =>
  useMutation<TreatmentGuideId, FetchError, TreatmentGuide>({
    mutationFn: mutations.PRACTITIONER_CREATE_TREATMENT_GUIDE.mutationFn,
    ...(options && options),
  })

export const usePractitionerUpdateTreatmentGuideMutation = (options?: {
  onSuccess: (data: TreatmentGuideId) => void
}) =>
  useMutation<TreatmentGuideId, FetchError, UpdateTreatmentGuideArgs>({
    mutationFn: mutations.PRACTITIONER_UPDATE_TREATMENT_GUIDE.mutationFn,
    ...(options && options),
  })

export const usePractitionerUpdateIsArchivedMutation = (options?: { onSuccess: (data: TreatmentGuideId) => void }) =>
  useMutation<TreatmentGuideId, FetchError, UpdateIsArchivedArgs>({
    mutationFn: mutations.PRACTITIONER_UPDATE_IS_ARCHIVED.mutationFn,
    ...(options && options),
  })

export const usePractitionerPatientInfoQuery = (patientId: string | undefined) =>
  useQuery<PatientInfo, FetchError, PatientInfo, string[]>({
    queryKey: [queries.PRACTITIONER_PATIENT_INFO.queryKey[0], patientId as string],
    queryFn: queries.PRACTITIONER_PATIENT_INFO.queryFn,
  })

export const usePractitionerPatientSignUpMutation = (options?: {
  onSuccess: (data: Patient) => void
  onError?: (err: FetchError) => void
}) =>
  useMutation<Patient, FetchError, PatientSignUp>({
    mutationFn: mutations.PRACTITIONER_PATIENT_SIGNUP.mutationFn,
    ...(options && options),
  })

export const usePractitionerGetPreAssignedUrlMutation = () =>
  useMutation<PresignedUrl, FetchError, TreatmentPlanUrl>({
    mutationFn: mutations.PRACTITIONER_TREATMENT_PLAN_URL.mutationFn,
  })

export const usePractitionerAddTreatmentPlan = () =>
  useMutation<TreatmentPlanSuccess, FetchError, AddTreatmentPlan>({
    mutationFn: mutations.PRACTITIONER_ADD_TREATMENT_PLAN.mutationFn,
  })

export const usePractitionerRemoveTreatmentPlan = () =>
  useMutation<void, FetchError, { filename: string }>({
    mutationFn: mutations.PRACTITIONER_REMOVE_TREATMENT_PLAN.mutationFn,
  })

export const usePractitionerResendInvite = (options?: { onSuccess: () => void }) =>
  useMutation<void, FetchError, TreatmentGuideId>({
    mutationFn: mutations.PRACTITIONER_RESEND_INVITE.mutationFn,
    ...(options && options),
  })

export const usePracticeGuidePractitionersQuery = (
  treatmentGuideId: string | undefined,
  options?: {
    enabled: boolean
    onSuccess?: (data: GuidePractitioner[]) => void
  },
) =>
  useQuery<GuidePractitioner[], FetchError, GuidePractitioner[], string[]>({
    queryKey: [queries.GUIDE_PRACTITIONER.queryKey[0], treatmentGuideId as string],
    queryFn: queries.GUIDE_PRACTITIONER.queryFn,
    ...(options && options),
  })

export const usePracticeAvailableInstalments = (
  { amount, treatmentGuideId }: { treatmentGuideId: string; amount: string },
  options?: { enabled: boolean },
) =>
  useQuery<DivideBuyInstalment[], FetchError, DivideBuyInstalment[], string[]>({
    queryKey: [queries.PRACTICE_AVAILABLE_INSTALMENTS.queryKey[0], amount, treatmentGuideId],
    queryFn: queries.PRACTICE_AVAILABLE_INSTALMENTS.queryFn,
    ...(options && options),
  })

export const usePractitionerGetMultiPreAssignedUrlMutation = () =>
  useMutation<MultiPresignedUrl, FetchError, MultiAttachmentUrl>({
    mutationFn: mutations.PRACTITIONER_ATTACHMENTS_URL.mutationFn,
  })

export const usePractitionerAddAttachment = () =>
  useMutation<AttachmentSuccess, FetchError, MultiAddAttachments>({
    mutationFn: mutations.PRACTITIONER_ADD_ATTACHMENTS.mutationFn,
  })

export const usePractitionerRemoveAttachment = () =>
  useMutation<void, FetchError, MultiAddAttachments>({
    mutationFn: mutations.PRACTITIONER_REMOVE_ATTACHMENTS.mutationFn,
  })

export const usePractitionerAccountPayments = () =>
  useMutation<StripeSession, FetchError, CheckoutSessionArgs>({
    mutationFn: mutations.PRACTITIONER_ACCOUNT_PAYMENTS.mutationFn,
  })

export const usePractitionerSendPlan = () =>
  useMutation<void, FetchError, SendPlanArgs>({
    mutationFn: mutations.PRACTITIONER_SEND_PLAN.mutationFn,
  })
