import { useCallback, useEffect, useMemo } from 'react'

import { usePatientAvailableInstalments, usePatientTreatmentGuideMagicLinkQuery } from '@shared/data/patient/hooks'
import { DivideBuyInstalment } from '@shared/data/types'
import { useFinanceInstalmentStore } from '@shared/stores/financeInstalmentsStore'
import { getTreatmentId } from '@shared/utils/getTreatmentId'

type MaxAvailableInstalment = DivideBuyInstalment & {
  max_price: number
}

type MinAvailableInstalment = DivideBuyInstalment & {
  min_price: number
}

export const useSetInstalments = (offersFinance: boolean) => {
  const { data } = usePatientTreatmentGuideMagicLinkQuery(getTreatmentId())
  const treatmentPrice = !data?.treatmentGuide?.price
    ? 0
    : data.treatmentGuide?.price - (data.treatmentGuide?.deposit || 0)

  const { data: instalments, refetch } = usePatientAvailableInstalments(
    {
      practiceId: data?.treatmentGuide.practiceId || '',
      amount: (treatmentPrice || 3000).toString(),
    },
    {
      enabled: false,
    },
  )
  const treatmentGuideLoaded = !!data?.treatmentGuide
  const { setInstalmentsData } = useFinanceInstalmentStore()

  useEffect(() => {
    if (offersFinance) {
      if (data?.treatmentGuide.practiceId) {
        refetch()
      }
    }
  }, [refetch, data?.treatmentGuide.practiceId, offersFinance])

  const maxLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          (instalments || []).filter(
            ({ max_price, type }) => max_price !== null && type !== 'representative_example',
          ) as MaxAvailableInstalment[]
        ).map(({ max_price }) => max_price),
      ).toString(),
    [instalments],
  )

  const minLoanAmount = useMemo(
    () =>
      Math.max(
        ...(
          (instalments || []).filter(
            ({ min_price, type }) => min_price !== null && type !== 'representative_example',
          ) as MinAvailableInstalment[]
        ).map(({ min_price }) => min_price),
      ).toString(),
    [instalments],
  )

  const setInstalments = useCallback(() => {
    if (instalments && treatmentGuideLoaded) {
      setInstalmentsData({
        isError: false,
        errorMessage: '',
        instalmentsRaw: instalments,
        representativeExample: instalments.find((item: DivideBuyInstalment) => item.type === 'representative_example'),
        maxLoanAmount,
        minLoanAmount,
        instalmentFlags: {
          regulated: instalments.some(
            (obj: DivideBuyInstalment) => obj.type === 'ibc' || (obj.type === 'ifc' && obj.term_length > 12),
          ),
          unregulated: instalments.some((obj: DivideBuyInstalment) => obj.type === 'ifc' && obj.term_length <= 12),
        },
      })
    }
  }, [instalments, setInstalmentsData, treatmentGuideLoaded, maxLoanAmount, minLoanAmount])

  return setInstalments
}
