import {
  AddGuidePractitioner,
  AddPractitioner,
  AddTreatmentPlan,
  AttachmentSuccess,
  CheckoutSessionArgs,
  GuidePractitioner,
  GuidePractitionerPatch,
  MultiAddAttachments,
  MultiAttachmentUrl,
  MultiPresignedUrl,
  Patient,
  PatientSignUp,
  PracticeDetails,
  PracticeDetailsPatch,
  Practitioner,
  PractitionerDetailsPatch,
  PresignedUrl,
  SendPlanArgs,
  StripeSession,
  TreatmentGuide,
  TreatmentGuideId,
  TreatmentPlanSuccess,
  TreatmentPlanUrl,
  UpdateIsArchivedArgs,
  UpdateTreatmentGuideArgs,
} from '@shared/data/types'
import { practiceFetch as fetch } from '@shared/utils/fetch'
import { apiUrls } from '@shared/utils/urls'

export const fetchers = {
  PRACTICE_DETAILS: ({ practiceId, ...data }: PracticeDetailsPatch): Promise<PracticeDetails> =>
    fetch({ url: apiUrls.practiceDetails(practiceId || ''), method: 'patch', data }),
  PRACTITIONER_ADD_USER: (data: AddPractitioner): Promise<Practitioner> =>
    fetch({ url: apiUrls.practitionerSignUpUser, method: 'post', data }),
  PRACTITIONER_UPDATE_DETAILS: ({ practitionerId, ...data }: PractitionerDetailsPatch): Promise<Practitioner> =>
    fetch({ url: apiUrls.practitionerUpdateDetails(practitionerId || ''), method: 'patch', data }),
  GUIDE_PRACTITIONER_ADD: (data: AddGuidePractitioner): Promise<AddGuidePractitioner> =>
    fetch({ url: apiUrls.guidePractitionerAdd, method: 'post', data }),
  GUIDE_PRACTITIONER_UPDATE: ({ id, ...data }: GuidePractitionerPatch): Promise<GuidePractitioner> =>
    fetch({ url: apiUrls.guidePractitionerUpdate(id || ''), method: 'patch', data }),
  PRACTITIONER_CREATE_TREATMENT_GUIDE: (data: TreatmentGuide): Promise<TreatmentGuideId> =>
    fetch({ url: apiUrls.practitionerTreatmentGuide, method: 'post', data }),
  PRACTITIONER_UPDATE_TREATMENT_GUIDE: (data: UpdateTreatmentGuideArgs): Promise<TreatmentGuideId> =>
    fetch({ url: apiUrls.practitionerTreatmentGuide, method: 'put', data }),
  PRACTITIONER_PATIENT_SIGNUP: (data: PatientSignUp): Promise<Patient> =>
    fetch({ url: apiUrls.patientSignUp, method: 'post', data }),
  PRACTITIONER_TREATMENT_PLAN_URL: (data: TreatmentPlanUrl): Promise<PresignedUrl> =>
    fetch({ url: apiUrls.practitionerTreatmentPlanUrl, method: 'post', data }),
  PRACTITIONER_ADD_TREATMENT_PLAN: ({ filename, ...data }: AddTreatmentPlan): Promise<TreatmentPlanSuccess> =>
    fetch({ url: apiUrls.practitionerAddTreatmentPlan(filename), method: 'patch', data }),
  PRACTITIONER_REMOVE_TREATMENT_PLAN: ({ filename }: { filename: string }): Promise<void> =>
    fetch({ url: apiUrls.practitionerAddTreatmentPlan(filename), method: 'delete' }),
  PRACTITIONER_UPDATE_IS_ARCHIVED: (data: UpdateIsArchivedArgs): Promise<TreatmentGuideId> =>
    fetch({ url: apiUrls.practitionerUpdateIsArchived, method: 'put', data }),
  PRACTITIONER_RESEND_INVITE: (data: TreatmentGuideId): Promise<void> =>
    fetch({ url: apiUrls.practitionerResendInvite, method: 'post', data }),
  PRACTITIONER_ATTACHMENTS_URL: (data: MultiAttachmentUrl): Promise<MultiPresignedUrl> =>
    fetch({ url: apiUrls.practitionerAttachmentsUrl, method: 'post', data }),
  PRACTITIONER_ADD_ATTACHMENTS: (data: MultiAddAttachments): Promise<AttachmentSuccess> =>
    fetch({ url: apiUrls.practitionerAddAttachments, method: 'patch', data }),
  PRACTITIONER_REMOVE_ATTACHMENTS: (data: MultiAddAttachments): Promise<void> =>
    fetch({ url: apiUrls.practitionerAddAttachments, method: 'delete', data }),
  PRACTITIONER_ACCOUNT_PAYMENTS: (data: CheckoutSessionArgs): Promise<StripeSession> =>
    fetch({ url: apiUrls.practitionerAccountPayments, method: 'post', data }),
  PRACTITIONER_SEND_PLAN: (data: SendPlanArgs): Promise<void> =>
    fetch({ url: apiUrls.practitionerSendPlan, method: 'post', data }),
}

export const mutations = {
  PRACTICE_DETAILS: {
    mutationFn: (data: PracticeDetailsPatch) => fetchers.PRACTICE_DETAILS(data),
  },
  PRACTITIONER_ADD: {
    mutationFn: (data: AddPractitioner) => fetchers.PRACTITIONER_ADD_USER(data),
  },
  PRACTITIONER_UPDATE: {
    mutationFn: (data: PractitionerDetailsPatch) => fetchers.PRACTITIONER_UPDATE_DETAILS(data),
  },
  GUIDE_PRACTITIONER_ADD: {
    mutationFn: (data: AddGuidePractitioner) => fetchers.GUIDE_PRACTITIONER_ADD(data),
  },
  GUIDE_PRACTITIONER_UPDATE: {
    mutationFn: (data: GuidePractitionerPatch) => fetchers.GUIDE_PRACTITIONER_UPDATE(data),
  },
  PRACTITIONER_CREATE_TREATMENT_GUIDE: {
    mutationFn: (data: TreatmentGuide) => fetchers.PRACTITIONER_CREATE_TREATMENT_GUIDE(data),
  },
  PRACTITIONER_UPDATE_TREATMENT_GUIDE: {
    mutationFn: (data: UpdateTreatmentGuideArgs) => fetchers.PRACTITIONER_UPDATE_TREATMENT_GUIDE(data),
  },
  PRACTITIONER_PATIENT_SIGNUP: {
    mutationFn: (data: PatientSignUp) => fetchers.PRACTITIONER_PATIENT_SIGNUP(data),
  },
  PRACTITIONER_TREATMENT_PLAN_URL: {
    mutationFn: (data: TreatmentPlanUrl) => fetchers.PRACTITIONER_TREATMENT_PLAN_URL(data),
  },
  PRACTITIONER_ADD_TREATMENT_PLAN: {
    mutationFn: (data: AddTreatmentPlan) => fetchers.PRACTITIONER_ADD_TREATMENT_PLAN(data),
  },
  PRACTITIONER_REMOVE_TREATMENT_PLAN: {
    mutationFn: (data: { filename: string }) => fetchers.PRACTITIONER_REMOVE_TREATMENT_PLAN(data),
  },
  PRACTITIONER_UPDATE_IS_ARCHIVED: {
    mutationFn: (data: UpdateIsArchivedArgs) => fetchers.PRACTITIONER_UPDATE_IS_ARCHIVED(data),
  },
  PRACTITIONER_RESEND_INVITE: {
    mutationFn: (data: TreatmentGuideId) => fetchers.PRACTITIONER_RESEND_INVITE(data),
  },
  PRACTITIONER_ATTACHMENTS_URL: {
    mutationFn: (data: MultiAttachmentUrl) => fetchers.PRACTITIONER_ATTACHMENTS_URL(data),
  },
  PRACTITIONER_ADD_ATTACHMENTS: {
    mutationFn: (data: MultiAddAttachments) => fetchers.PRACTITIONER_ADD_ATTACHMENTS(data),
  },
  PRACTITIONER_REMOVE_ATTACHMENTS: {
    mutationFn: (data: MultiAddAttachments) => fetchers.PRACTITIONER_REMOVE_ATTACHMENTS(data),
  },
  PRACTITIONER_ACCOUNT_PAYMENTS: {
    mutationFn: (data: CheckoutSessionArgs) => fetchers.PRACTITIONER_ACCOUNT_PAYMENTS(data),
  },
  PRACTITIONER_SEND_PLAN: {
    mutationFn: (data: SendPlanArgs) => fetchers.PRACTITIONER_SEND_PLAN(data),
  },
}
