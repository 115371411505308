import {
  DivideBuyInstalment,
  FileUrl,
  GuidePractitioner,
  PatientInfo,
  PatientUserDetails,
  PracticeDetails,
  TreatmentDefinition,
  TreatmentGuide,
  TreatmentGuideRes,
} from '@shared/data/types'
import { patientFetch as fetch } from '@shared/utils/fetch'
import { apiUrls } from '@shared/utils/urls'

import { AttachmentSignedUrl } from '../types'

const fetchers = {
  PATIENT_INFO: ({ queryKey }: { queryKey: string[] }): Promise<PatientInfo> =>
    fetch({ url: apiUrls.patientInfo(queryKey[1]) }),
  PATIENT_USER_DETAILS: (): Promise<PatientUserDetails> => fetch({ url: apiUrls.patientUserDetails }),
  PATIENT_TREATMENT_PLAN: ({ queryKey }: { queryKey: string[] }): Promise<FileUrl> | false => {
    if (queryKey[1]) {
      return fetch({ url: apiUrls.patientTreatmentPlan(queryKey[1]) })
    }
    return false
  },
  PATIENT_ORDER_STATUS: () => fetch({ url: apiUrls.patientOrderStatus }),
  TREATMENT_DEFINITION: ({ queryKey }: { queryKey: string[] }): Promise<TreatmentDefinition> =>
    fetch({ url: apiUrls.treatmentDefinition(queryKey[1]) }),
  PATIENT_AVAILABLE_INSTALMENTS: ({ queryKey }: { queryKey: string[] }): Promise<DivideBuyInstalment[]> =>
    fetch({
      method: 'POST',
      url: apiUrls.patientAvailableInstalments(queryKey[2]),
      data: { orderTotal: queryKey[1] },
    }),
  PRACTICE_DETAILS: ({ queryKey }: { queryKey: string[] }): Promise<PracticeDetails> =>
    fetch({ url: apiUrls.practiceDetails(queryKey[1]) }),
  PATIENT_TREATMENT_GUIDE: ({ queryKey }: { queryKey: string[] }): Promise<TreatmentGuide> =>
    fetch({ url: apiUrls.patientTreatmentGuide(queryKey[1]) }),
  PATIENT_TREATMENT_DEFINITION: ({ queryKey }: { queryKey: string[] }): Promise<TreatmentDefinition> =>
    fetch({ url: apiUrls.patientTreatmentDefinition(queryKey[1]) }),
  PATIENT_TREATMENT_GUIDE_MAGIC_LINK: ({ queryKey }: { queryKey: string[] }): Promise<TreatmentGuideRes> =>
    fetch({ url: apiUrls.patientTreatmentGuideMagicLink(queryKey[1]) }),
  GUIDE_PRACTITIONER: (): Promise<GuidePractitioner[]> => fetch({ url: apiUrls.guidePractitionerGet }),
  PATIENT_ATTACHMENTS: (): Promise<AttachmentSignedUrl[]> => fetch({ url: apiUrls.patientAttachments }),
}

export const queries = {
  PATIENT_USER_DETAILS: {
    queryKey: ['PATIENT_USER_DETAILS'],
    queryFn: fetchers.PATIENT_USER_DETAILS,
  },
  PATIENT_ORDER_STATUS: {
    queryKey: ['PATIENT_ORDER_STATUS'],
    queryFn: fetchers.PATIENT_ORDER_STATUS,
  },
  TREATMENT_DEFINITION: {
    queryKey: ['TREATMENT_DEFINITION'],
    queryFn: fetchers.TREATMENT_DEFINITION,
  },
  PATIENT_TREATMENT_GUIDE: {
    queryKey: ['TREATMENT_GUIDE'],
    queryFn: fetchers.PATIENT_TREATMENT_GUIDE,
  },
  PATIENT_TREATMENT_DEFINITION: {
    queryKey: ['PATIENT_TREATMENT_DEFINITION'],
    queryFn: fetchers.PATIENT_TREATMENT_DEFINITION,
  },
  PATIENT_TREATMENT_GUIDE_MAGIC_LINK: {
    queryKey: ['TREATMENT_GUIDE'],
    queryFn: fetchers.PATIENT_TREATMENT_GUIDE_MAGIC_LINK,
  },
  PATIENT_AVAILABLE_INSTALMENTS: {
    queryKey: ['PATIENT_AVAILABLE_INSTALMENTS'],
    queryFn: fetchers.PATIENT_AVAILABLE_INSTALMENTS,
  },
  PATIENT_TREATMENT_PLAN: {
    queryKey: ['PATIENT_TREATMENT_PLAN'],
    queryFn: fetchers.PATIENT_TREATMENT_PLAN,
  },
  GUIDE_PRACTITIONER: {
    queryKey: ['GUIDE_PRACTITIONER'],
    queryFn: fetchers.GUIDE_PRACTITIONER,
  },
  PATIENT_ATTACHMENTS: {
    queryKey: ['PATIENT_ATTACHMENTS'],
    queryFn: fetchers.PATIENT_ATTACHMENTS,
  },
}
