import { useMutation, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import {
  CheckoutSession,
  CheckoutSessionArgs,
  CreateOrderArgs,
  DivideBuyInstalment,
  FetchError,
  FileUrl,
  GuidePractitioner,
  PatientFinanceManual,
  PatientFinanceRedirect,
  PatientOrderStatus,
  PatientOrderStatusArgs,
  PatientResetMagicLinkArgs,
  PatientSoftSearchKeyArgs,
  PatientUserDetails,
  PatientUserDetailsPatch,
  SoftSearchLink,
  TreatmentDefinition,
  TreatmentGuide,
  TreatmentGuideRes,
  UpdateAnswers,
  UpdateConfirmationArgs,
  UpdateDecisionArgs,
  UpdateQuiz,
  UpdateStripePaymentStatusArgs,
} from '@shared/data/types'

import { AttachmentSignedUrl } from '../types'

import { mutations } from './mutations'
import { queries } from './queries'

// NOTE: custom hooks make testing easier
// https://tanstack.com/query/v4/docs/react/guides/testing

export const usePatientUserDetailsQuery = (enabled = true) =>
  useQuery<PatientUserDetails, FetchError>({
    ...queries.PATIENT_USER_DETAILS,
    enabled,
  })

export const usePatientUserDetailsMutation = (options?: { onError: (err: FetchError) => void }) =>
  useMutation<PatientUserDetailsPatch, FetchError, PatientUserDetailsPatch>({
    mutationFn: mutations.PATIENT_USER_DETAILS.mutationFn,
    ...(options && options),
  })

export const usePatientQuizMutation = (options?: { onError: (err: AxiosError) => void }) =>
  useMutation<UpdateQuiz, AxiosError, UpdateAnswers & { type: 'smile' }>({
    mutationFn: mutations.PATIENT_QUIZ.mutationFn,
    ...(options && options),
  })

export const usePatientSoftSearchKeyMutation = (options?: {
  onSettled?: () => void
  onSuccess?: (data: SoftSearchLink) => void
  onError?: () => void
}) =>
  useMutation<SoftSearchLink, FetchError, PatientSoftSearchKeyArgs>({
    mutationFn: mutations.PATIENT_SOFT_SEARCH_KEY.mutationFn,
    ...(options && options),
  })

export const usePatientFinanceRedirectMutation = (options?: { onSuccess: (data: PatientFinanceRedirect) => void }) =>
  useMutation<PatientFinanceRedirect, FetchError>({
    mutationFn: mutations.PATIENT_FINANCE_REDIRECT.mutationFn,
    ...(options && options),
  })

export const usePatientFinanceManualMutation = (options?: {
  onSuccess: () => void
  onError?: (err: FetchError) => void
}) =>
  useMutation<PatientFinanceManual, FetchError>({
    mutationFn: mutations.PATIENT_FINANCE_MANUAL.mutationFn,
    ...(options && options),
  })

export const usePatientOrderStatus = () =>
  useMutation<PatientOrderStatus, FetchError, PatientOrderStatusArgs>({
    mutationFn: mutations.PATIENT_ORDER_STATUS.mutationFn,
    meta: {
      req: 'splashKey',
    },
  })

export const useTreatmentDefinitionQuery = (treatmentName: string | undefined, options?: { enabled: boolean }) =>
  useQuery<TreatmentDefinition, FetchError, TreatmentDefinition, string[]>({
    queryKey: [queries.TREATMENT_DEFINITION.queryKey[0], treatmentName as string],
    queryFn: queries.TREATMENT_DEFINITION.queryFn,
    ...(options && options),
  })

export const usePatientAvailableInstalments = (
  { amount, practiceId }: { practiceId: string; amount: string },
  options?: { enabled: boolean },
) =>
  useQuery<DivideBuyInstalment[], FetchError, DivideBuyInstalment[], string[]>({
    queryKey: [queries.PATIENT_AVAILABLE_INSTALMENTS.queryKey[0], amount, practiceId],
    queryFn: queries.PATIENT_AVAILABLE_INSTALMENTS.queryFn,
    ...(options && options),
  })

export const useGuidePractitionersQuery = (options?: {
  enabled: boolean
  onSuccess?: (data: GuidePractitioner[]) => void
}) =>
  useQuery<GuidePractitioner[], FetchError>({
    ...queries.GUIDE_PRACTITIONER,
    ...(options && options),
  })

export const usePatientTreatmentGuideQuery = (practiceId: string, options?: { onSuccess: () => void }) =>
  useQuery<TreatmentGuide, FetchError, { treatmentGuide: TreatmentGuide }, string[]>({
    queryKey: [queries.PATIENT_TREATMENT_GUIDE.queryKey[0], practiceId],
    queryFn: queries.PATIENT_TREATMENT_GUIDE.queryFn,
    ...(options && options),
  })

export const usePatientTreatmentGuideMagicLinkQuery = (
  treatmentGuideId: string,
  options?: { onSuccess: (data: TreatmentGuideRes) => void },
) =>
  useQuery<TreatmentGuideRes, FetchError, TreatmentGuideRes, string[]>({
    queryKey: [queries.PATIENT_TREATMENT_GUIDE_MAGIC_LINK.queryKey[0], treatmentGuideId],
    queryFn: queries.PATIENT_TREATMENT_GUIDE_MAGIC_LINK.queryFn,
    ...(options && options),
  })

export const usePatientTreatmentDefinitionQuery = (treatmentName: string | undefined, options?: { enabled: boolean }) =>
  useQuery<TreatmentDefinition, FetchError, TreatmentDefinition, string[]>({
    queryKey: [queries.PATIENT_TREATMENT_DEFINITION.queryKey[0], treatmentName as string],
    queryFn: queries.PATIENT_TREATMENT_DEFINITION.queryFn,
    ...(options && options),
  })

export const usePatientTreatmentPlanQuery = (
  filename: string,
  options?: { onSuccess?: () => void; enabled?: boolean },
) =>
  useQuery<FileUrl | false, FetchError, FileUrl, string[]>({
    queryKey: [queries.PATIENT_TREATMENT_PLAN.queryKey[0], filename],
    queryFn: queries.PATIENT_TREATMENT_PLAN.queryFn,
    ...(options && options),
  })

export const usePatientAttachmentsQuery = ({ enabled = true }) =>
  useQuery<AttachmentSignedUrl[], FetchError>({
    ...queries.PATIENT_ATTACHMENTS,
    enabled,
  })

export const usePatientCreateOrderMutation = (options?: { onSuccess: () => void }) =>
  useMutation<null, FetchError, CreateOrderArgs>({
    mutationFn: mutations.PATIENT_CREATE_ORDER.mutationFn,
    ...(options && options),
  })

export const usePatientResetMagicLink = (options?: { onSuccess: () => void }) =>
  useMutation<null, FetchError, PatientResetMagicLinkArgs>({
    mutationFn: mutations.PATIENT_RESET_MAGIC_LINK.mutationFn,
    ...(options && options),
  })

export const usePatientUpdateDecision = (options?: { onSuccess: () => void }) =>
  useMutation<null, FetchError, UpdateDecisionArgs>({
    mutationFn: mutations.PATIENT_UPDATE_DECISION.mutationFn,
    ...(options && options),
  })

export const usePatientUpdateConfirmation = () =>
  useMutation<null, FetchError, UpdateConfirmationArgs>({
    mutationFn: mutations.PATIENT_UPDATE_CONFIRMATION.mutationFn,
  })

export const useStripeProductCheckoutSession = () =>
  useMutation<CheckoutSession, FetchError, CheckoutSessionArgs>({
    mutationFn: mutations.STRIPE_CHECKOUT_SESSION.mutationFn,
  })

export const useStripeSubscriptionCheckoutSession = () =>
  useMutation<CheckoutSession, FetchError, CheckoutSessionArgs>({
    mutationFn: mutations.STRIPE_SUBSCRIPTION_CHECKOUT_SESSION.mutationFn,
  })

export const useUpdateStripePaymentStatus = () =>
  useMutation<null, FetchError, UpdateStripePaymentStatusArgs>({
    mutationFn: mutations.UPDATE_STRIPE_PAYMENT_STATUS.mutationFn,
  })
