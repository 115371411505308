import { useCallback } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import mixpanel from 'mixpanel-browser'

import { usePracticePractitionersListQuery } from '@shared/data/practice/hooks'
import { queries } from '@shared/data/practice/queries'
import { PracticeDetails } from '@shared/data/types'
import { useAuth } from '@shared/stores/authStore'

export const usePracticeMixpanel = () => {
  const { trackingId } = useAuth.getState()
  const { data: practitionersData } = usePracticePractitionersListQuery()
  const queryClient = useQueryClient()

  const identify = useCallback(
    async (trackingId: string) => {
      const practice: PracticeDetails | undefined = practitionersData?.practiceId
        ? await queryClient.ensureQueryData({
            queryKey: [queries.PRACTICE_DETAILS.queryKey[0], practitionersData?.practiceId],
            queryFn: queries.PRACTICE_DETAILS.queryFn,
          })
        : undefined
      if (!practice?.demo) {
        mixpanel.identify(trackingId)
        mixpanel.people.set({ 'Practice name': practice?.name, 'userType': 'practitioner' })
      }
    },
    [practitionersData?.practiceId, queryClient],
  )

  const trackPracticeEvent = useCallback(
    async (name: string, props?: Record<string, unknown>) => {
      const practice: PracticeDetails | undefined = practitionersData?.practiceId
        ? await queryClient.ensureQueryData({
            queryKey: [queries.PRACTICE_DETAILS.queryKey[0], practitionersData?.practiceId],
            queryFn: queries.PRACTICE_DETAILS.queryFn,
          })
        : undefined
      if (practice && !practice.demo) {
        mixpanel.track(name, {
          'Practice name': practice.name,
          'userType': 'practitioner',
          trackingId,
          ...props,
        })
      }
    },
    [practitionersData?.practiceId, trackingId, queryClient],
  )

  return {
    identify,
    trackPracticeEvent,
  }
}
